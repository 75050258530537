
<template>
  <slot name="header" />
  <form
    role="form"
    method="post"
    action=""
  >
    <div class="form-group ">
      <label>Kualifikasi Prestasi</label>
      <select
        v-model="field.kualifikasi"
        class="selectKualifikasi col-sm-12 col-md-6 form-control"
        data-placeholder="Piliih Kualifikasi"
        data-allow-clear="1"
      >
        <option
          v-for="kualfikasi in refKualifikasi"
          :key="kualfikasi.id"
          :value="kualfikasi.id"
        >
          {{ kualfikasi.nama_kualifikasi }}
        </option>
      </select>
    </div>
    <div class="form-group ">
      <label>Nama Prestasi <small>(prestasi/magang/pengalaman/kegiatan)</small></label>
      <input
        v-model="field.uraian"
        type="text"
        class="form-control form-control-rounded"
      >
    </div>
    <div class="form-group">
      <label>Jenjang</label>
      <select
        v-model="field.jenjang"
        class="selectKualifikasi col-sm-12 col-md-6 form-control"
        data-placeholder="Piliih Kualifikasi"
        data-allow-clear="1"
      >
        <option
          v-for="jenjang in refJenjang"
          :key="jenjang.id"
          :value="jenjang.id"
        >
          {{ jenjang.nama_jenjang }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Capaian </label>
      <select
        v-model="field.capaian"
        class="selectKualifikasi col-sm-12 col-md-6 form-control"
        data-placeholder="Piliih Kualifikasi"
        data-allow-clear="1"
      >
        <option
          v-for="capaian in refCapaian"
          :key="capaian.id"
          :value="capaian.id"
        >
          {{ capaian.nama_capaian }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Tempat </label>
      <input
        v-model="field.tempat"
        type="text"
        class="form-control col-sm-12 col-md-10"
        placeholder=""
      >
    </div>
    <div class="form-group">
      <label>Tanggal <small>( Tanggal Perolehan Sertifikat/Piagam/Kegiatan )</small></label>
      <div class="input-group m-b col-sm-12 col-md-4">
        <span class="input-group-addon">
          <i class="glyphicon glyphicon-calendar fa fa-calendar" /></span>
        <input
          v-model="field.tanggal"
          type="text"
          class="tglcapaian date"
        >
      </div>
    </div>
    <div class="form-group">
      <label>Berkas </label>
      <div
        class="fileinput fileinput-new input-group col-md-12"
        data-provides="fileinput"
      >
        <span
          class="input-group-addon btn btn-indigo btn-file "
          style="border-radius: 4px 0px 0px 4px;"
        >
          <span class="fileinput-new">File</span>
          <span class="fileinput-exists">Change</span>
          <input
            ref="file"
            type="file"
            @change="beforeUpload()"
          >
        </span>
        <a
          href="#"
          class="input-group-addon btn btn-danger  hover fileinput-exists"
          data-dismiss="fileinput"
        >Remove</a>
        <div
          class="form-control"
          data-trigger="fileinput"
        >
          <span class="fileinput-filename" />
        </div>
      </div>
    </div>
    <br>
    <button
      type="button"
      class="btn btn-success btn-rounded box-shadow"
      @click="handleUpload"
    >
      Ajukan Prestasi
    </button>
  </form>
</template>
<script>
import { uploadPrestasi } from '@/services/prestasi.service'
import { defineComponent, reactive, toRefs, computed, ref } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    profil: {
      type: Object,
      default (rawProps) {
        return { id_sms: '', id_reg_pd: '' }
      }
    },
    refKualifikasi: {
      type: Object,
      default () {
        return {}
      }
    },
    refJenjang: {
      type: Object,
      default () {
        return {}
      }
    },
    refCapaian: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  emits: ['handle-loading', 'handle-upload'],
  setup (props, { attrs, slots, emit, expose }) {
    const store = useStore()
    const users = store.getters.user
    const fileList = ref([])
    const field = reactive({
      id_sms: '',
      id_reg_pd: '',
      kualifikasi: '',
      uraian: '',
      tempat: '',
      tanggal: '',
      jenjang: '',
      capaian: '',
      files: '',
      keterangan: ''
    })
    const state = reactive({
      pembimbing: {},
      isLoading: false
    })

    const handleUpload = () => {
      const formData = new FormData()
      formData.append('id_sms', users.id_prodi)
      formData.append('id_reg_pd', users.id_reg_pd)
      formData.append('kualifikasi', field.kualifikasi)
      formData.append('uraian', field.uraian)
      formData.append('tempat', field.tempat)
      formData.append('tanggal', field.tanggal)
      formData.append('jenjang', field.jenjang)
      formData.append('capaian', field.capaian)
      formData.append('files', fileList.value)
      emit('handle-loading', true)
      uploadPrestasi(formData).then(response => {
        emit('handle-upload', response)
        emit('handle-loading', false)
        if (response.error_code === 200) {
          field.kualifikasi = ''
          field.uraian = ''
          field.tempat = ''
          field.tanggal = ''
          field.jenjang = ''
          field.capaian = ''
          field.files = ''
        }
      }).catch(() => {
        emit('handle-loading', false)
      })
    }

    const loading = computed(() => {
      return state.isLoading
    })
    return {
      ...toRefs(state),
      users,
      field,
      fileList,
      loading,
      handleUpload
    }
  },
  mounted () {
    /* global $ */
    const vm = this
    $('.tglcapaian').datepicker({
      clearBtn: true,
      autoclose: true,
      format: 'dd/mm/yyyy'
    }).on('changeDate', function (ev) {
      vm.field.tanggal = ev.target.value
    })
  },
  methods: {
    beforeUpload () {
      this.fileList = this.$refs.file.files[0]
      return false
    }
  }
})
</script>
