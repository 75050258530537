import request from '../config/request'

export function getRefKualifikasi (parameter) {
  return request.get('/skpi/ref/kualifikasi', { params: parameter })
}

export function getRefJenjang (parameter) {
  return request.get('/skpi/ref/jenjang', { params: parameter })
}

export function getRefCapaian (parameter) {
  return request.get('/skpi/ref/capaian', { params: parameter })
}

export function getRiwayatAjuan (parameter) {
  return request.get('/skpi/riwayat/pengajuan', { params: parameter })
}

export function uploadPrestasi (parameter) {
  return request.post('/skpi/create/pengajuan', parameter, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function getPrestasi (parameter) {
  return request.get('/skpi/prestasi', { params: parameter })
}
export function putDokPrestasi (parameter) {
  return request.post('/skpi/prestasi/unggahdokumen', parameter, { headers: { 'Content-Type': 'multipart/form-data' } })
}
