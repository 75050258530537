<!-- eslint-disable -->
<template>
  <loading v-model:active="loading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-sm-12 col-md-7">
        <div class="card radius-lr20">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Halaman Pengajuan Data Prestasi
            </p>
          </div>
          <div class="card-body padding-t-0">
            <form-input-prestasi
              :refKualifikasi="sourceRefKualifikasi"
              :refJenjang="sourceRefJenjang"
              :refCapaian="sourceRefCapaian"
              @handle-loading="handleLoading"
              @handle-upload="handleUpload"
            >
            </form-input-prestasi>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <widget-status-pengajuan
          v-if="isWidgetStatusPengajuan"
          :profil="users">
        </widget-status-pengajuan>
      </div>
    </div>
</template>
<script>

import Loading from 'vue-loading-overlay'
import { getRefKualifikasi, getRefJenjang, getRefCapaian } from '@/services/prestasi.service'
import FormInputPrestasi from '@/components/prestasi/FormInputPrestasi.vue'
import WidgetStatusPengajuan from '@/components/prestasi/WidgetStatusPengajuan.vue'
import { useStore } from 'vuex'
import { defineComponent, reactive, toRefs, computed } from 'vue'
export default defineComponent({
  name: 'PrestasiAdd',
  components: {
    Loading,
    FormInputPrestasi,
    WidgetStatusPengajuan
  },
  data () {
    const store = useStore()
    const users = store.getters.user

    const state = reactive({
      sourceRefKualifikasi: {},
      sourceRefCapaian: {},
      sourceRefJenjang: {},
      isWidgetStatusPengajuan: true,
      isLoading: false
    })

    const handleRefKualifikasi = () => {
      state.isLoading = true
      getRefKualifikasi({ }).then(response => {
        state.sourceRefKualifikasi = response?.records || []
        state.isLoading = false
      })
    }

    const handleRefCapaian = () => {
      state.isLoading = true
      getRefCapaian({ }).then(response => {
        state.sourceRefCapaian = response?.records || []
        state.isLoading = false
      })
    }

    const handleRefJenjang = () => {
      state.isLoading = true
      getRefJenjang({ }).then(response => {
        state.sourceRefJenjang = response?.records || []
        state.isLoading = false
      })
    }

    const loading = computed(() => {
      return state.isLoading
    })

    return {
      ...toRefs(state),
      users,
      handleRefKualifikasi,
      handleRefCapaian,
      handleRefJenjang,
      loading,
      errorMsg: '',
      fullPage: true
    }
  },
  created () {
    this.handleRefKualifikasi()
    this.handleRefCapaian()
    this.handleRefJenjang()
  },
  methods: {
    /* global $ */
    handleUpload (source) {
      if (source.error_code === 200) {
        $.toast({
          heading: 'Good job!',
          text: 'Data prestasi berhasil di ajukan',
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'success',
          hideAfter: 3000,
          stack: 1
        })
      } else {
        $.toast({
          heading: 'Error !',
          text: source.messages,
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'error',
          hideAfter: 3000,
          stack: 1
        })
      }
    },
    handleLoading (source) {
      this.isLoading = source
      this.isWidgetStatusPengajuan = !(source)
    }
  }
})
</script>
