<template>
  <div
    v-for="ajuan in sourceData"
    :key="ajuan.id"
    class="widget white-bg friends-group clearfix"
  >
    <div class="pull-right">
      <span class="badge badge-info">{{ ajuan.status_ajuan }}</span>
    </div>
    <small class="text-muted">Kualifikasi</small>
    <p>{{ ajuan.nama_kualifikasi }}</p>
    <small class="text-muted">Nama Prestasi </small>
    <p>{{ ajuan.uraian }}</p>
  </div>
</template>

<script>
import { getRiwayatAjuan } from '@/services/prestasi.service'
import { defineComponent, reactive, toRefs, computed } from 'vue'
export default defineComponent({
  props: {
    profil: {
      type: Object,
      default () {
        return { id_sms: '', id_reg_pd: '' }
      }
    }
  },
  emits: ['handle-loading', 'handle-upload'],
  setup (props, { attrs, slots, emit, expose }) {
    const state = reactive({
      sourceData: {},
      isLoading: false
    })

    const loading = computed(() => {
      return state.isLoading
    })

    const handlePengajuan = () => {
      state.isLoading = true
      getRiwayatAjuan({ id_reg_pd: props.profil.id_reg_pd, terima: 'no' }).then(response => {
        state.sourceData = response?.records || []
        state.isLoading = false
      })
    }

    return {
      ...toRefs(state),
      loading,
      handlePengajuan
    }
  },
  created () {
    this.handlePengajuan()
  }
})
</script>
